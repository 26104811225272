<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="type" @change="loadOrders(true)">
                <el-radio-button :label="null">全部</el-radio-button>
                <el-radio-button :label="9">待发货</el-radio-button>
                <el-radio-button :label="15">待买家收货</el-radio-button>
                <el-radio-button :label="90">退款/售后</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 240px;" @search="loadOrders(true)" />
                </div>
            </div>
        </el-card>

        <el-card v-for="order in orders.data" :key="order.id" shadow="never" class="mt-3">
            <template #header>
                <div class="row no-gutters">
                    <div class="col">
                        {{order.buyerName}}
                        <span class="text-info">{{order.billNumber}}</span>
                    </div>
                    <div class="col-auto">
                        {{order.paymentMethodName}}：
                        <span class="text-danger">￥{{order.paymentAmount}}</span>
                    </div>
                </div>
            </template>

            <div v-for="orderItem in order.orderItems" :key="orderItem.id"
                :class="{'text-info':orderItem.refundAmount!==null,'bg-info-light':orderItem.refundAmount!==null}"
                class="border border-light rounded mb-1 row no-gutters">
                <div class="row-auto">
                    <el-image :src="orderItem.productSnapshot.pictureUrl"
                        :preview-src-list="[orderItem.productSnapshot.pictureUrl]" fit="fill"
                        class="d-block rounded m-1" style="width: 64px; height: 64px;" />
                </div>
                <div class="col p-2">
                    <div>{{orderItem.productSnapshot.name}}</div>
                    <div class="text-danger mt-2">￥{{orderItem.productSnapshot.price}}</div>
                </div>
                <div class="col-auto p-2">
                    <el-button v-if="orderItem.refundAmount===null" type="text" class="p-0 mt-3"
                        @click="showRefundOrderItemDialog(orderItem)">
                        退款
                    </el-button>
                    <div v-else class="text-sm text-right pt-2">
                        <div>已退款：￥{{orderItem.refundAmount}}</div>
                        <div>{{$moment.sdt(orderItem.refundTime)}}</div>
                    </div>
                </div>
            </div>

            <div class="row no-gutters mt-3">
                <div class="col">
                    <el-button v-if="order.status==9" type="text" class="p-0" @click="acceptOrder(order.id)">
                        接单
                    </el-button>
                    <el-button v-if="order.status==9||order.status==10" type="text" class="p-0"
                        @click="showDeliveryOrderDialog(order)">
                        发货
                    </el-button>
                    <div v-if="order.expressNumber">
                        {{order.expressCompany}}：
                        <a href="#">{{order.expressNumber}}</a>
                    </div>
                </div>
                <div class="col-auto text-info">
                    {{$moment.sdt(order.createTime)}}
                </div>
            </div>
        </el-card>

        <el-card shadow="never" class="mt-3">
            <el-pagination layout="total,->,prev,pager,next" :total="orders.totalRecords"
                :current-page="ordersPagination.currentPage" :page-size="ordersPagination.pageSize"
                @current-change="ordersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="deliveryOrderDialogVisible" title="发货" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="快递公司">
                    <el-input v-model="order.expressCompany" placeholder="快递公司" />
                </el-form-item>
                <el-form-item label="快递单号">
                    <el-input v-model="order.expressNumber" placeholder="快递单号" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="deliveryOrder">确定</el-button>
                <el-button @click="deliveryOrderDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="refundOrderItemDialogVisible" title="退款" :close-on-click-modal="false" width="400px">
            <el-form>
                <el-form-item label="退款金额">
                    <el-input-number v-model="orderItem.refundAmount" controls-position="right" />
                </el-form-item>
                <el-form-item label="退款原因">
                    <el-input v-model="orderItem.refundReason" placeholder="退款原因" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="refundOrderItem">确定</el-button>
                <el-button @click="refundOrderItemDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                type: null,
                key: null,
                orders: {},
                ordersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                order: {},
                orderItem: {},
                deliveryOrderDialogVisible: false,
                refundOrderItemDialogVisible: false,
            };
        },
        methods: {
            async loadOrders(resetPage = false) {
                if (resetPage) {
                    this.ordersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Order/GetOrdersBySupplier', {
                    params: {
                        type: this.type,
                        key: this.key,
                        pageIndex: this.ordersPagination.currentPage - 1,
                        pageSize: this.ordersPagination.pageSize,
                    }
                });
                this.orders = response.data;
            },
            ordersPaginationCurrentChange(page) {
                this.ordersPagination.currentPage = page;
                this.loadOrders();
            },
            async acceptOrder(id) {
                if (!confirm('确定要接单吗？')) {
                    return;
                }

                await this.$axios.post('/api/Order/AcceptOrder', { id: id });
                this.loadOrders();
            },
            showDeliveryOrderDialog(order) {
                this.order = JSON.parse(JSON.stringify(order));
                this.deliveryOrderDialogVisible = true;
            },
            async deliveryOrder() {
                if (!confirm('确定要发货吗？')) {
                    return;
                }

                await this.$axios.post('/api/Order/DeliveryOrder', {
                    id: this.order.id,
                    expressCompany: this.order.expressCompany,
                    expressNumber: this.order.expressNumber,
                });
                this.deliveryOrderDialogVisible = false;
                this.loadOrders();
            },
            showRefundOrderItemDialog(orderItem) {
                this.orderItem = JSON.parse(JSON.stringify(orderItem));
                this.refundOrderItemDialogVisible = true;
            },
            async refundOrderItem() {
                if (!confirm('确定要退款吗？')) {
                    return;
                }

                await this.$axios.post('/api/Order/RefundOrderItem', {
                    orderItemId: this.orderItem.id,
                    refundAmount: this.orderItem.refundAmount,
                    refundReason: this.orderItem.refundReason,
                });
                this.refundOrderItemDialogVisible = false;
                this.loadOrders();
            },
        },
        created() {
            this.loadOrders();
        },
    };
</script>